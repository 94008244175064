<template>
    <div class="fino-container">
        <div class="finotech">
            <h2> احراز هویت فینوتک </h2>
            <h4> کد ۶ رقمی ارسال شده به شماره خود را وارد نمایید</h4>
            <label for="">
                <input disabled v-model="state.userInfo.nationalCode" type="text">
                <span :class="state.lan=='En'?'span-en':'span-fa'"  class="disable"> کدملی </span>
            </label>
            <label for="">
                <input disabled v-model="state.userInfo.mobileNumber" type="text">
                <span :class="state.lan=='En'?'span-en':'span-fa'" class="disable"> شماره موبایل </span>
            </label>
            <label @click="blanckSpace=true" for="">
                <CodeInput :fields="5"  :loading="false" class="verif-code" @change="onChange" @complete="submit" />
            </label>
            <div class="btns">
                <button @click.prevent="submit" :disabled="otp.code.length !== 6" class="submit"> ثبت </button>
                <button disabled v-if="countDown !== 0" class="counter">{{countDown}} ثانیه </button>
                <button v-else class="counter"> ارسال مجدد </button>
            </div>
        </div>
    </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
    export default {
        name:'FinoTech',
        components:{
            CodeInput
        },
        data(){
            return{
                fields: {
                type: Number,
                default: 5
                },
                blanckSpace:false,
                countDown:120,
                timeOut:'',
                otp: {
                code: ''
                },
            }
        },
        methods:{
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                if(this.state.lan=='Fa'){
                    this.$error('زمان شما به اتمام رسید', '')
                } else {
                    this.$error('Your time is up', '')
                }
            }
        },
        async send() {
            // this.reset()
            this.state.loading = true
            const res = await this.$axios.get(`/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`)
            if (res.message === 'Sms sent to customer.') {
                if (this.countDown === 0) {
                    this.countDown = 120
                    this.countDownTimer()
                }
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            this.state.loading = true
            if (this.otp.code.length === 5 || this.loading) {
                this.loading = true
                let [res, ] = await this.$http.get('/finotech/otp', {
                    params: {
                        otp: this.otp.code,
                        email: this.state.userInfo.email
                    }
                })
                this.loading = false
                if (res) {
                        await this.$error('', 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.', 'success')
                    this.$router.push({
                        name: 'Validation'
                    })
                }
            }
            this.state.loading = false
        },
        },
        mounted(){
            // this.send();
        },
        created() {
            this.countDownTimer()
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },

    }
</script>

<style lang="scss" scoped>
.counter{
    background: black;
    color: white;
    padding: 8px 0;
    border-radius: 8px;
}
.btns{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    width: 100%;
    button{
        width: 100%;
        max-width: 400px;
    }
}
.finotech{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    row-gap: 16px;
}
    label{
        width: 100%;
        max-width: 400px;
    position: relative;
    span {
    transition: 1s;
        z-index: -1;
        color: var(--secondary-color);
        position: absolute;
        bottom: 7px;
        right: 5px;
        font-weight: 400;
        font-size: clamp(10px,1vw,14px);
        box-sizing: border-box;
        padding: 0 5px;
    }
    input,textarea{
    color: var(--secondary-color);
    box-sizing: border-box;
    padding: 8px 16px;
    background: none;
    border: 1px solid #a4c6defa;
    width: 100%;
    border-radius: 4px;
    resize: none;
    height: 100%;
}
}
</style>